import { get } from 'lodash';
import {
  isLayoutPGOneColumn,
  isLayoutPGSideBySide,
  IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH,
  DESKTOP_LAYOUT_TYPES_TO_MOBILE,
  LAYOUT_PG_GRID,
  SECTION_POST_LIST,
  getLayoutTypePath,
} from '@wix/communities-blog-client-common';

import { getAppSettings } from '../../common/selectors/app-settings-base-selectors';
import { getDefaultLayout } from '../../common/selectors/layout-selectors';
import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';

export const getPostListLayoutType = ({ state, isMobile }) => {
  const isMobileLayoutSettingsEnabled = getIsMobileSettingEnabled(state, IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH);
  const mobileLayoutType = isMobileLayoutSettingsEnabled && getLayoutTypeParam({ state, isMobile: true });
  const defaultLayout = getDefaultLayout(state);
  const desktopLayoutType = getLayoutTypeParam({ state, defaultLayout });

  if (isMobile && desktopLayoutType && !mobileLayoutType) {
    return DESKTOP_LAYOUT_TYPES_TO_MOBILE[desktopLayoutType];
  }

  let layoutType = (isMobile && mobileLayoutType) || desktopLayoutType;

  if (isMobile && (isLayoutPGSideBySide(layoutType) || isLayoutPGOneColumn(layoutType))) {
    layoutType = LAYOUT_PG_GRID;
  }

  return layoutType;
};

const getLayoutTypeParam = ({ state, isMobile, defaultLayout }) => {
  return get(getAppSettings(state), getLayoutTypePath(SECTION_POST_LIST, isMobile), defaultLayout);
};
