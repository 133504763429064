import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { getIsICUEnabled, getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import CategoryMenu from '../category-menu';
import styles from './app-root.scss';

const AppRoot = () => {
  return (
    <ResponsiveListener>
      <div className={styles.baseStyles}>
        <CategoryMenu />
        <AppLoaded />
      </div>
    </ResponsiveListener>
  );
};

AppRoot.propTypes = {
  language: PropTypes.string,
  translations: PropTypes.object.isRequired,
  sectionUrl: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  sectionUrl: getSectionUrl(state),
  isICUEnabled: getIsICUEnabled(state),
});

export default flowRight(withReduxStore, connect(mapRuntimeToProps))(AppRoot);
